import React, { useState } from 'react';
import UserProfile from '../components/UserProfile';
import { MenuOutlined } from '@ant-design/icons';
import MobileNavigationDrawer from './MobileNavigationDrawer';
import NavbarLinks from './NavbarLinks';



const Navbar = () => {
    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
    return (
        <div className='nav-bar flex flex-row  justify-between items-center px-6 py-4'>
            <div className='mobile-menu p-2 lg:hidden'>
                <div>
                    <MenuOutlined onClick={() => setIsMobileMenuVisible(true)} />
                </div>
            </div>
            {isMobileMenuVisible ? <MobileNavigationDrawer onClose={setIsMobileMenuVisible} open={isMobileMenuVisible} /> : ''}
            <div className='nav-bar-links hidden lg:block'>
                <NavbarLinks />
            </div>
            <div className='user-profile-container'>
                <UserProfile />
            </div>

        </div>
    )
}

export default Navbar