import React, { useEffect, useContext, useRef } from 'react';
import { ChatContext } from '../context/chat/ChatContext';
import ChatMessage from '../components/chat/ChatMessage';

const Messages = () => {
    const { messages } = useContext(ChatContext);
    const messageEndRef = useRef(null);

    useEffect(() => {
        messageEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    return (
        <div className='messages-scroll-section flex flex-col h-full bg-white w-95p m-4 p-4 mb-0 overflow-y-auto rounded-2xl lg:rounded-none shadow-lg lg:shadow-none'>
            <div className='messages-container w-90p md:80p lg:w-60p mx-auto'  >
                {messages?.map((message, index) => (
                    <ChatMessage
                        key={index}
                        role={message?.role}
                        content={message?.content}
                        citations={message?.citations}
                        streamingMessage={message?.streamingMessage}
                    />
                ))}
            </div>
            <div ref={messageEndRef}></div>
        </div>
    )
}

export default Messages