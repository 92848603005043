import React from 'react';
import CustomIcon from './CustomIcon';
import { Card } from 'antd';

const ProductTile = ({ productTileData }) => {
    const { logo = '', header = '', subHeader = '', productTileBrandName = '' } = productTileData;
    return (
        <div className='product-tile-container'>
            <Card size='small' className='shadow-md lg:m-2  lg:p-6 lg:shadow-lg' onClick={() => console.log('card clicked')}>
                <CustomIcon className='my-2' src={logo} alt={`Logo  for ${header}`} size={28} />
                <h3 className='my-1  font-bold text-sm lg:text-lg'>
                    {productTileBrandName ?
                        <span className='pr-1'>
                            {productTileBrandName}
                            <sup className='-top-3 text-[10px]'>TM</sup>
                        </span> : ''}
                    {header}
                    {!productTileBrandName ? <sup className='-top-3 text-[10px]'>TM</sup> : ''}
                </h3>
                <p className='my-1'>{subHeader}</p>
            </Card>
        </div>
    )
}

export default ProductTile