import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';
import { useBrowserCloseOrReload } from '../hooks/useBrowserCloseOrReload';

const Layout = () => {
    useBrowserCloseOrReload();
    return (
        <div className='flex flex-row h-screen w-screen bg-slate-50 lg:bg-white'>
            <Sidebar />
            <div className='flex flex-col w-full'>
                <Navbar />
                <div className='main-container h-screen lg:bg-slate-50 relative'>
                    <div className='outlet-container h-[78vh] md:h-[75vh] lg:h-[72vh]'>
                        <Outlet />
                    </div>
                    <div className='footer-container absolute bottom-3 md:bottom-6 w-full'>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Layout